var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

/**
 * @license
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function () {
  'use strict';
  /**
   * Class constructor for Textfield MDL component.
   * Implements MDL component design pattern defined at:
   * https://github.com/jasonmayes/mdl-component-design-pattern
   *
   * @constructor
   * @param {HTMLElement} element The element that will be upgraded.
   */

  var MaterialTextfield = function MaterialTextfield(element) {
    (this || _global).element_ = element;
    (this || _global).maxRows = (this || _global).Constant_.NO_MAX_ROWS; // Initialize instance.

    this.init();
  };

  window["MaterialTextfield"] = MaterialTextfield;
  /**
   * Store constants in one place so they can be updated easily.
   *
   * @enum {string | number}
   * @private
   */

  MaterialTextfield.prototype.Constant_ = {
    NO_MAX_ROWS: -1,
    MAX_ROWS_ATTRIBUTE: "maxrows"
  };
  /**
   * Store strings for class names defined by this component that are used in
   * JavaScript. This allows us to simply change it in one place should we
   * decide to modify at a later date.
   *
   * @enum {string}
   * @private
   */

  MaterialTextfield.prototype.CssClasses_ = {
    LABEL: "mdl-textfield__label",
    INPUT: "mdl-textfield__input",
    IS_DIRTY: "is-dirty",
    IS_FOCUSED: "is-focused",
    IS_DISABLED: "is-disabled",
    IS_INVALID: "is-invalid",
    IS_UPGRADED: "is-upgraded",
    HAS_PLACEHOLDER: "has-placeholder"
  };
  /**
   * Handle input being entered.
   *
   * @param {Event} event The event that fired.
   * @private
   */

  MaterialTextfield.prototype.onKeyDown_ = function (event) {
    var currentRowCount = event.target.value.split("\n").length;

    if (event.keyCode === 13) {
      if (currentRowCount >= (this || _global).maxRows) {
        event.preventDefault();
      }
    }
  };
  /**
   * Handle focus.
   *
   * @param {Event} event The event that fired.
   * @private
   */


  MaterialTextfield.prototype.onFocus_ = function (event) {
    (this || _global).element_.classList.add((this || _global).CssClasses_.IS_FOCUSED);
  };
  /**
   * Handle lost focus.
   *
   * @param {Event} event The event that fired.
   * @private
   */


  MaterialTextfield.prototype.onBlur_ = function (event) {
    (this || _global).element_.classList.remove((this || _global).CssClasses_.IS_FOCUSED);
  };
  /**
   * Handle reset event from out side.
   *
   * @param {Event} event The event that fired.
   * @private
   */


  MaterialTextfield.prototype.onReset_ = function (event) {
    this.updateClasses_();
  };
  /**
   * Handle class updates.
   *
   * @private
   */


  MaterialTextfield.prototype.updateClasses_ = function () {
    this.checkDisabled();
    this.checkValidity();
    this.checkDirty();
    this.checkFocus();
  }; // Public methods.

  /**
   * Check the disabled state and update field accordingly.
   *
   * @public
   */


  MaterialTextfield.prototype.checkDisabled = function () {
    if ((this || _global).input_.disabled) {
      (this || _global).element_.classList.add((this || _global).CssClasses_.IS_DISABLED);
    } else {
      (this || _global).element_.classList.remove((this || _global).CssClasses_.IS_DISABLED);
    }
  };

  MaterialTextfield.prototype["checkDisabled"] = MaterialTextfield.prototype.checkDisabled;
  /**
  * Check the focus state and update field accordingly.
  *
  * @public
  */

  MaterialTextfield.prototype.checkFocus = function () {
    if (Boolean((this || _global).element_.querySelector(":focus"))) {
      (this || _global).element_.classList.add((this || _global).CssClasses_.IS_FOCUSED);
    } else {
      (this || _global).element_.classList.remove((this || _global).CssClasses_.IS_FOCUSED);
    }
  };

  MaterialTextfield.prototype["checkFocus"] = MaterialTextfield.prototype.checkFocus;
  /**
   * Check the validity state and update field accordingly.
   *
   * @public
   */

  MaterialTextfield.prototype.checkValidity = function () {
    if ((this || _global).input_.validity) {
      if ((this || _global).input_.validity.valid) {
        (this || _global).element_.classList.remove((this || _global).CssClasses_.IS_INVALID);
      } else {
        (this || _global).element_.classList.add((this || _global).CssClasses_.IS_INVALID);
      }
    }
  };

  MaterialTextfield.prototype["checkValidity"] = MaterialTextfield.prototype.checkValidity;
  /**
   * Check the dirty state and update field accordingly.
   *
   * @public
   */

  MaterialTextfield.prototype.checkDirty = function () {
    if ((this || _global).input_.value && (this || _global).input_.value.length > 0) {
      (this || _global).element_.classList.add((this || _global).CssClasses_.IS_DIRTY);
    } else {
      (this || _global).element_.classList.remove((this || _global).CssClasses_.IS_DIRTY);
    }
  };

  MaterialTextfield.prototype["checkDirty"] = MaterialTextfield.prototype.checkDirty;
  /**
   * Disable text field.
   *
   * @public
   */

  MaterialTextfield.prototype.disable = function () {
    (this || _global).input_.disabled = true;
    this.updateClasses_();
  };

  MaterialTextfield.prototype["disable"] = MaterialTextfield.prototype.disable;
  /**
   * Enable text field.
   *
   * @public
   */

  MaterialTextfield.prototype.enable = function () {
    (this || _global).input_.disabled = false;
    this.updateClasses_();
  };

  MaterialTextfield.prototype["enable"] = MaterialTextfield.prototype.enable;
  /**
   * Update text field value.
   *
   * @param {string} value The value to which to set the control (optional).
   * @public
   */

  MaterialTextfield.prototype.change = function (value) {
    (this || _global).input_.value = value || "";
    this.updateClasses_();
  };

  MaterialTextfield.prototype["change"] = MaterialTextfield.prototype.change;
  /**
   * Initialize element.
   */

  MaterialTextfield.prototype.init = function () {
    if ((this || _global).element_) {
      (this || _global).label_ = (this || _global).element_.querySelector("." + (this || _global).CssClasses_.LABEL);
      (this || _global).input_ = (this || _global).element_.querySelector("." + (this || _global).CssClasses_.INPUT);

      if ((this || _global).input_) {
        if ((this || _global).input_.hasAttribute(
        /** @type {string} */
        (this || _global).Constant_.MAX_ROWS_ATTRIBUTE)) {
          (this || _global).maxRows = parseInt((this || _global).input_.getAttribute(
          /** @type {string} */
          (this || _global).Constant_.MAX_ROWS_ATTRIBUTE), 10);

          if (isNaN((this || _global).maxRows)) {
            (this || _global).maxRows = (this || _global).Constant_.NO_MAX_ROWS;
          }
        }

        if ((this || _global).input_.hasAttribute("placeholder")) {
          (this || _global).element_.classList.add((this || _global).CssClasses_.HAS_PLACEHOLDER);
        }

        (this || _global).boundUpdateClassesHandler = (this || _global).updateClasses_.bind(this || _global);
        (this || _global).boundFocusHandler = (this || _global).onFocus_.bind(this || _global);
        (this || _global).boundBlurHandler = (this || _global).onBlur_.bind(this || _global);
        (this || _global).boundResetHandler = (this || _global).onReset_.bind(this || _global);

        (this || _global).input_.addEventListener("input", (this || _global).boundUpdateClassesHandler);

        (this || _global).input_.addEventListener("focus", (this || _global).boundFocusHandler);

        (this || _global).input_.addEventListener("blur", (this || _global).boundBlurHandler);

        (this || _global).input_.addEventListener("reset", (this || _global).boundResetHandler);

        if ((this || _global).maxRows !== (this || _global).Constant_.NO_MAX_ROWS) {
          // TODO: This should handle pasting multi line text.
          // Currently doesn't.
          (this || _global).boundKeyDownHandler = (this || _global).onKeyDown_.bind(this || _global);

          (this || _global).input_.addEventListener("keydown", (this || _global).boundKeyDownHandler);
        }

        var invalid = (this || _global).element_.classList.contains((this || _global).CssClasses_.IS_INVALID);

        this.updateClasses_();

        (this || _global).element_.classList.add((this || _global).CssClasses_.IS_UPGRADED);

        if (invalid) {
          (this || _global).element_.classList.add((this || _global).CssClasses_.IS_INVALID);
        }

        if ((this || _global).input_.hasAttribute("autofocus")) {
          (this || _global).element_.focus();

          this.checkFocus();
        }
      }
    }
  }; // The component registers itself. It can assume componentHandler is available
  // in the global scope.


  componentHandler.register({
    constructor: MaterialTextfield,
    classAsString: "MaterialTextfield",
    cssClass: "mdl-js-textfield",
    widget: true
  });
})();

export default {};